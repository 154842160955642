import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlSerializer, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private urlSerializer: UrlSerializer,
    private deviceService: DeviceDetectorService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (route.queryParams.start_param) {
      const parts = route.queryParams.start_param.split('-');
      if (parts.length === 2) {
        const routePath = `/${parts[0]}/${parts[1]}`;
        this.router.navigate([routePath], {
          queryParams: {},
        });
      }
    }

    if (route.queryParams.token != undefined && route.queryParams.expires != undefined) {
      this.authService.setAuthDataFromUrl(route.queryParams.token, route.queryParams.expires);

      return new Promise((resolve) => {
        this.authService.getUserByToken().subscribe((res) => {
          // удаляем токен из адресной строки если это не мобильник
          if (!this.deviceService.isMobile()) {
            this.router.navigate([], {
              queryParams: { token: null, expires: null },
              queryParamsHandling: 'merge',
            });
          }

          resolve(this.handle());
        });
      });
    } else {
      return this.handle();
    }
  }

  handle(): Promise<boolean | UrlTree> {
    const currentUser = this.authService.currentUserValue;

    if (currentUser) {
      // logged in so return true
      return new Promise((resolve) => {
        resolve(true);
      });
    }

    // not logged in so redirect to login page with the return url
    this.authService.logout();
    return new Promise((resolve) => {
      resolve(false);
    });
  }
}
