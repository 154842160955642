import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserModel } from '@app/modules/core';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  getProfileData(): Observable<UserModel> {
    return this.http.get<UserModel>(`/v2/profile/`);
  }

  editUserInfo(firstName: string, lastName: string) {
    return this.http.patch('/v2/user/', {
      first_name: firstName,
      last_name: lastName,
      friendly_name: firstName + ' ' + lastName,
    });
  }

  putPhoneUpdate(phoneNumber: string) {
    return this.http.put('/v2/user/contacts-update/phone', {
      phone_number: phoneNumber,
    });
  }

  verifyPhoneUpdate(smsCode: string) {
    return this.http.post('/v2/user/contacts-update/phone/verify', {
      sms_code: smsCode,
    });
  }

  createTelegramAppointLink() {
    return this.http.get(`/v2/notification/telegram/get-link`);
  }
}
