import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, throwError } from 'rxjs';
import { map, catchError, finalize } from 'rxjs/operators';
import { UserModel, AuthModel } from '../models';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProfileService } from '@app/pages/profile/profile.service';

export type UserType = UserModel | undefined;

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = [];
  private authLocalStorageToken = 'authToken';

  // public fields
  currentUser$: Observable<UserType>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserType>;
  isLoadingSubject: BehaviorSubject<boolean>;

  get currentUserValue(): UserType {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserType) {
    this.currentUserSubject.next(user);
  }

  setAuthDataFromUrl(token: string, expires: string) {
    let authData = new AuthModel();
    authData.authToken = token;
    authData.expires = new Date(expires);
    this.tokenService.setAuthData(authData);
  }

  constructor(
    private router: Router,
    private tokenService: TokenService,
    private profileService: ProfileService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserType>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
  }

  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    /* this.router.navigate(['/auth/login'], {
      queryParams: {},
    }); */
    // window.location.href = 'https://auth.indigolab.shop/seller/auth?callback=https://seller.ilai.io/dashboard';
    this.router.navigateByUrl('/auth/login');
  }

  getUserByToken(): Observable<UserType> {
    const auth = this.tokenService.authData;
    if (!auth || !auth.authToken) {
      return of(undefined);
    }

    this.isLoadingSubject.next(true);
    return this.profileService.getProfileData().pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error)),
      map((user: UserType) => {
        if (user) {
          this.currentUserSubject.next(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => {
        this.isLoadingSubject.next(false);
      }),
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status == 401) {
      this.logout();
      return throwError(() => new Error('Auth invalid.'));
    }

    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else {
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }

    return throwError(() => new Error('Something bad happened; please try again later.'));
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }
}
